import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { DrupalTemplate, DrupalNode, DrupalProcessedText, DrupalLink, DrupalBanner } from '../drupal'
import Layout from '../layouts'
import { widths } from '../style/variables'
import Container from '../components/Container'
import Stack from '../components/Stack'
import Banner from '../components/Banner'
import Button from '../components/blocks/Button'
import { featureTest } from '../utils'

export interface ThankYouPageNode extends DrupalNode {
	body: DrupalProcessedText
	field_link_some_group: DrupalLink
	relationships: {
		field_banner?: DrupalBanner
	}
}

const StyledContainer = styled(Container)`
	/** Make content more readable by limiting max-width. */
	article {
		max-width: ${widths.md}px;
		margin: 0 auto var(--spacing) auto;
		text-align: center;
	}

	.btn,
	button {
		color: white;
	}
`

/**
 * @todo: thank you page should probably prevent robots.
 */
const ThankYouPageTemplate: DrupalTemplate<ThankYouPageNode> = ({ data: { node } }) => {
	const [email, setEmail] = useState('')

	useEffect(() => {
		// Fetch email from localStorage
		if (featureTest.localStorage()) {
			const email = window.localStorage.getItem('email')

			if (typeof email === 'string' && email.indexOf('@') > -1) {
				setEmail(email)
			}
		}
	}, [])

	return (
		<Layout node={node}>
			<main>
				<Stack>
					<Banner image={node.relationships.field_banner} />

					<StyledContainer>
						<article>
							<h1>{node.title}</h1>
							<div dangerouslySetInnerHTML={{ __html: node.body.processed.replace('@email', email) }} />
							<Button link={node.field_link_some_group} />
						</article>
					</StyledContainer>
				</Stack>
			</main>
		</Layout>
	)
}

export default ThankYouPageTemplate

export const pageQuery = graphql`
	query ThankYouPageTemplateQuery($drupalId: String!) {
		node: nodeThankYouPage(drupal_id: { eq: $drupalId }) {
			langcode
			title
			metatag_normalized {
				tag
				attributes {
					name
					content
					property
					href
					rel
				}
			}
			body {
				processed
			}
			field_link_some_group {
				uri
				title
			}
			relationships {
				field_banner {
					...DrupalBanner
				}
			}
		}
	}
`
